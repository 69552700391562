import axios from 'axios';
import { useState, useEffect } from 'react';

import { useAuthToken } from '@sb/web-modules';
import { settings } from '../data/settings';

import { useLoadingScreen, useAlertDialog } from '@sb/web-components';

export const useQadhaPrayersCount = (refreshToggle: boolean) => {
    const token: any = useAuthToken();
    const showLoader = useLoadingScreen();
    const showAlert = useAlertDialog();
    const [count, setCount] = useState(null);

    useEffect(() => {

        if(!token) return;

        showLoader(true);
        const fetchCount = async () => {
            try {
                const resp = await axios.get(`${settings.qpApiBaseUrl}/counts`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setCount(resp.data);
            } catch (ex: any) {
                console.log(ex);
                showAlert(ex.response?.data?.error);
            }
            showLoader(false);
        };
        fetchCount();
    }, [refreshToggle, token, showLoader, showAlert])
    return count;
};

export const useQadhaPrayersHistory = (refreshToggle: boolean) => {
    const token: any = useAuthToken();
    const showLoader = useLoadingScreen();
    const showAlert = useAlertDialog();
    const [history, setHistory] = useState({items: []});

    useEffect(() => {

        if(!token) return;

        showLoader(true);
        const fetchHistory = async () => {
            try{
                const resp = await axios.get(`${settings.qpApiBaseUrl}/history`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setHistory(resp.data);
            } catch (ex: any) {
                console.log(ex);
                showAlert(ex.response?.data?.error);
            }
            showLoader(false);
        };
        fetchHistory();
    }, [refreshToggle, token, showLoader, showAlert])
    return history;
};