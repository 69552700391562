import { useEffect, useState } from 'react';

import './App.css';
import { useQadhaPrayersCount } from './apis/prayers';
import { PrayersHistory } from './components/prayers-history';
import { RemainingPrayers } from './components/remaining-prayers';
import { AuthenticatedUser, enableGAuth } from '@sb/web-modules';
import { BulkEdit } from './components/bulk-edit';
import { SBComponents } from '@sb/web-components';
import { useSignOut, useUserProfile } from '@sb/web-modules';
import { settings } from './data/settings';
import { BrowserRouter } from 'react-router-dom';

function App() {

  const [refreshToggle, setRefreshToggle] = useState(true);
  const [bulkEdit, setBulkEdit] = useState(false);

  const qpCount = useQadhaPrayersCount(refreshToggle);

  const profile = useUserProfile();
  const signOut = useSignOut();

  const refreshNow = () => setRefreshToggle(!refreshToggle);
  const switchBulkView = () => setBulkEdit(!bulkEdit);
  
  useEffect(() => {
    enableGAuth(settings.appId);
  }, []);

  return (
    <>
      <SBComponents />
      <BrowserRouter>
        <AuthenticatedUser appId={settings.appId} redirectUrl='https://qp.zaizia.com/'>
          <div className="p-4 grid grid-cols-6 sm:grid-cols-12 justify-center space-x-2">
            <h1 className="col-span-6 sm:col-span-12 text-3xl flex justify-center mb-2">
              ﷽
            </h1>
            <div className="col-span-6 sm:col-span-12 flex mb-2 border rounded p-2">
              <button className="col-span-2 bg-blue-500 text-white rounded h-10 hover:bg-blue-700 px-4"
                onClick={switchBulkView}>Switch View</button>
                <div className="flex flex-1 justify-end place-items-center">
                  {profile? (profile as any).email  : null}
                  [<button onClick={() => signOut()}><small className="text-blue-500">Sign Out</small></button>]
                </div>
            </div>
            {bulkEdit?
              <BulkEdit qpCount={qpCount} refreshNow={refreshNow} /> :
              <RemainingPrayers qpCount={qpCount} refreshNow={refreshNow} />
            }
            <div className="col-span-6 grid-cols-6 border p-2 mt-2">
              <h2 className="text-3xl flex font-bold justify-center">Salaat</h2>
              <div className="">
                Through salaat:
                <ul className='px-8 list-decimal'>
                  <li>Seek, help of Allah</li>
                  <li>Get, peace of mind, mental energy and clarity</li>
                  <li>Leave everything, all worries, to Allah</li>
                </ul>
                <p><strong>Namaz-e-Ãyat</strong> It is offered at times of lunar/solar eclipses and earthquake. 
                  It is recommended to offer at times of thunder and lightning.<br />
                  It is better to offer it at times of any situation causing stress, anxiety and/or fear.</p>
              </div>
            </div>
          </div>
          <div className='ml-6 mr-4'>
            <div className="flex justify-center mb-2">
              <h2 className="text-3xl font-bold">History</h2>
              <button className="col-span-2 bg-blue-500 hover:bg-blue-700 text-white rounded h-10 ml-2 p-2 px-6"
                        onClick={() => refreshNow()}>Reload</button>
            </div>
            <PrayersHistory qpCount={qpCount} refreshToggle={refreshToggle} />
          </div>
        </AuthenticatedUser>
      </BrowserRouter>
    </>
  );
}

export default App;
