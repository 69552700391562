import React from 'react';

import { DateTime } from 'luxon';

import { settings } from '../data/settings';

import { useQadhaPrayersHistory } from '../apis/prayers';


export const PrayersHistory = ({ qpCount, refreshToggle }: { qpCount: any, refreshToggle: any }) => {

    const order = settings.order;
    const prayers = settings.prayers;
    const qpHistory = useQadhaPrayersHistory(refreshToggle);
    
    const prayersStats = (rec: { [x: string]: any; }, prev: { [x: string]: number; }) => {

        const stats: string[] = [];
        order.map(p => {
            if(rec[p] !== prev[p]) {
            stats.push(`${prayers[p as keyof typeof prayers].short}: ${prev[p] - rec[p]}`)
            }

            return null;
        });

        return stats.join(', ');  
        };

        let prevRec = qpCount || {};
        const processHistoryRec = (rec: { [x: string]: any; }, i: number) => {

        const altColorClass= i % 2 ? 'bg-green-50' : 'bg-yellow-50';

        const resp = (
            <div key={i} className={`grid grid-cols-12 border m-1 p-2 items-center ${altColorClass}`}>
                <div className="col-span-5">{DateTime.fromMillis(rec['timestamp'] * 1000).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}</div>
                <div className="col-span-3">{DateTime.fromMillis(rec['timestamp'] * 1000).toLocaleString(DateTime.TIME_SIMPLE)}</div>
                <div className="col-span-4 flex justify-center">{prayersStats(rec, prevRec)}</div>
            </div>);

        prevRec = rec;
        return resp;
    };

    return (qpHistory ?    
        (<div className="p-2 border">
            {
                qpHistory['items'].map((rec: any, i: number) => processHistoryRec(rec, i))
            }
        </div>)
        : (<span className="col-span-6 flex justify-center items-center text-3xl">Please wait, loading</span>)
    );
};