export const settings = {
    appId: '08081b0f-5dee-48bd-b5f6-2de3efe9ce94',
    qpApiBaseUrl: 'https://api.qp.zaizia.com',
    order: [
        'maghrib', 'isha', 'fajr', 'zohr', 'asr', 'ayat', 'mannat', 'fast'
    ],
    prayers: {
        maghrib: { id: 'maghrib', short:'M', name: 'Maghrib' },
        isha: { id: 'isha', short: 'I', name: 'Isha' },
        fajr: { id: 'fajr', short:'F', name: 'Fajar' },
        zohr: { id: 'zohr', short: 'Z', name: 'Zohar' },
        asr: { id: 'asr', short: 'A', name: 'Asr' },
        ayat: { id: 'ayat', short: 'Ã', name: 'Ãyat' },
        mannat: { id: 'mannat', short: 'M̃', name: 'M̃annat' },
        fast: { id: 'fast', short: 'ᵮ', name: 'ᵮast'}
    }
};