import React from 'react';
import axios from 'axios';

import { settings } from '../data/settings';
import { useAuthToken } from '@sb/web-modules';

import { useConfirmationDialog, useLoadingScreen } from '@sb/web-components';

export const RemainingPrayers = ({ qpCount, refreshNow }: {qpCount: any, refreshNow: any }) => {
    const token: any = useAuthToken();
    const showLoading = useLoadingScreen();
    const showConfirm = useConfirmationDialog();

    const order = settings.order;
    const prayers = settings.prayers;

    const decrementCount = (prayerId:string) => {
      
      if (!token) return;

      showConfirm(`Are you sure want to descrement count for the ${prayers[prayerId as keyof typeof prayers].name} prayer?`, async () => {
        const obj = {
          [prayerId]: 1
        };
    
        showLoading(true);
        try {
          await axios.delete(`${settings.qpApiBaseUrl}/counts`, { 
            headers: {
              Authorization: `Bearer ${token}`
            },
            data: JSON.stringify(obj) 
          });
        } catch (ex: any) {
          console.log(ex);
          showLoading(ex.response?.data?.error);
        }
        showLoading(false);
        refreshNow();
      });
    };

    return qpCount ?
        (<dl className="col-span-6 grid grid-cols-6 items-center p-2 border space-y-2 pb-4 mt-2">
          {
            order.map((id, i) => {
              const prayersCount = qpCount[id as keyof typeof prayers] || 0;
              return <React.Fragment key={i}>
                { i > 0 && <hr className='col-span-6' /> }
                <dt className="col-span-2 font-bold">{prayers[id as keyof typeof prayers].name}</dt>
                <dd className="col-span-2">{prayersCount}</dd>
                <button className={ 'col-span-2 bg-blue-500 text-white rounded h-10 ' + (prayersCount > 0 ? 'hover:bg-blue-700' : 'opacity-50 cursor-not-allowed') }
                        onClick={() => { prayersCount > 0 && decrementCount(id) }}>↓Decrement↓</button>
              </React.Fragment>
          })}
        </dl>)
        : (<span className="col-span-6 flex justify-center items-center text-3xl">Please wait, loading</span>);
};