import React, { useState } from 'react';
import axios from 'axios';

import { settings } from '../data/settings';
import { useAuthToken } from '@sb/web-modules';
import { useLoadingScreen, useAlertDialog, useConfirmationDialog } from '@sb/web-components';

export const BulkEdit = ({ qpCount, refreshNow }: {qpCount: any, refreshNow: any}) => {

    const order = settings.order;
    const prayers = settings.prayers;

    const token: any = useAuthToken();
    const showLoading = useLoadingScreen();
    const showAlert = useAlertDialog();
    const showConfirm = useConfirmationDialog();

    const [bulkUpdateValues, setBulkUpdateValues] = useState({});
    const updateInputVal = (key: string, val: number) => {

        const entry = { add: 0, sub: 0};
        if (val > 0) {
            entry.add = val;
            if (key in bulkUpdateValues) {
                entry.sub = (bulkUpdateValues as any)[key].sub;
            }
        }

        if (val < 0) {
            entry.sub = val;
            if (key in bulkUpdateValues) {
                entry.add = (bulkUpdateValues as any)[key].add;
            }
        }

        setBulkUpdateValues({
            ...bulkUpdateValues,
            [key]: entry
        });
    };

    const getValue = (id: string, positive: boolean) => {
        if (id in bulkUpdateValues) {
            if (!positive) {
                return -(bulkUpdateValues as any)[id].sub
            } else {
                return (bulkUpdateValues as any)[id].add
            }
        }

        return 0;
    };

    const saveAll = async () => {
        const data = {};
        let somethingChanged = false;
        order.forEach(id => {
            if (id in bulkUpdateValues) {
                const diff = (bulkUpdateValues as any)[id].add + (bulkUpdateValues as any)[id].sub;
                if (diff !== 0) {
                    (data as any)[id] = diff;
                    somethingChanged = true;
                }
            }
        });
        
        console.log('saveAll', {data});

        if (somethingChanged) {

            showConfirm('Are you sure want to update?', async () => {

                showLoading(true);
                try {
                    await axios.delete(`${settings.qpApiBaseUrl}/counts`, { 
                        headers: {
                        Authorization: `Bearer ${token}`
                        },
                        data: JSON.stringify(data) 
                    });
                    setBulkUpdateValues({});
                } catch (ex: any) {
                    console.log(ex);
                    showAlert(ex.response?.data?.error);
                }
                showLoading(false);
                refreshNow();
            });
        }
    };

    return qpCount ?
        (
        <>
            <dl className="col-span-6 grid grid-cols-8 items-center p-2 border space-y-2 pb-4 mt-2">
            {
                order.map((id, i) => {
                const prayersCount = qpCount[id as keyof typeof prayers] || 0;
                return (
                <React.Fragment key={i}>
                    { i > 0 && <hr className='col-span-8' /> }
                    <dt className="col-span-3 font-bold">&nbsp;{(prayers as any)[id].name}</dt>
                    <dd className="col-span-1">{prayersCount}</dd>
                    <div className="col-span-2 flex items-center justify-center">
                        +&nbsp;<input type="text" className='border rounded w-12 p-1' value={getValue(id, false)}
                            onChange={(e) => updateInputVal(id, -parseInt(e.target.value))} />
                    </div>
                    <div className="col-span-2 flex items-center justify-center">
                        -&nbsp;<input type="text" className='border rounded w-12 p-1' value={getValue(id, true)}
                            onChange={(e) => updateInputVal(id, parseInt(e.target.value))} />
                    </div>
                </React.Fragment>)
            })}
                <hr className='col-span-8' />
                <div className="col-span-8 p-2 space-y-2 mt-2 flex justify-center">
                    <button className="col-span-2 bg-blue-500 text-white rounded h-10 hover:bg-blue-700 px-4"
                                onClick={saveAll}>Save Changes</button>
                </div>
            </dl>
        </>)
        : (<span className="col-span-6 flex justify-center items-center text-3xl">Please wait, loading</span>);
};